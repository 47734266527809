export default {
  WithoutItemCard: {
    field: {
      title: 'You still have no active sowing',
      text: 'All your sowings will appear here.',
      create_field: 'Create field',
    },
    sowing: {
      title: 'You still have no active sowing',
      text: 'All your sowings will appear here.',
      execute_sowing: 'Execute sowing',
      add_sowing: 'Add sowing',
    },
    filter: {
      not_found_title: 'No results found',
      not_found_text: 'Try changing the filters',
    },
    spraying: {
      add_spraying: 'Add spraying',
      plan: 'Plan',
    },
    field_sowing: {
      text: 'There are no other sowings in this field',
      title: '',
    },
    program: {
      add_program: 'New program',
    },
  },
}
