export default {
  WithoutItemCard: {
    field: {
      title: 'Todavía no tienes siembra activa',
      text: 'Todas tus siembras aparecerán aquí.',
      create_field: 'Crear lote',
    },
    sowing: {
      title: 'Todavía no tienes siembra activa',
      text: 'Todas tus siembras aparecerán aquí.',
      execute_sowing: 'Realizar la siembra',
      add_sowing: 'Agregar siembra',
    },
    filter: {
      not_found_title: 'No encontramos resultados',
      not_found_text: 'Prueba a cambiar los filtros',
    },
    spraying: {
      add_spraying: 'Registrar aplicación',
      plan: 'Plan',
    },
    field_sowing: {
      text: 'No hay otras siembras en este lote',
      title: '',
    },
    program: {
      add_program: 'Nuevo programa',
    },
  },
}
